import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

function BlockedDomainsThumbnail(props) {
  const { availWidth, availHeight } = window.screen; //eslint-disable-line
  const width = availWidth < 960 ? availWidth * 0.75 : 200; //changes the width of a container with the bubbles
  const height = availWidth < 960 ? availWidth * 0.75 : 200;
  const data = props.domainsBlocked;
  const dashboardDetails = props.uniqueMalwareDomainsBlocked;
  const dashboardDetailsObject = setMaliciousData(
    props.uniqueMalwareDomainsBlocked,
  );
  let maliciousTags = new Set([
    "malware",
    "phishing",
    "botnet",
    "keylogger",
    "spam",
    "spyware",
    "proxy",
  ]);
  let adultSiteTags = new Set(["adult"]);
  const d3Container = useRef(null);

  useEffect(() => {
    if (data != null) {
      if (data.length > 0 && dashboardDetails !== null) {
        // console.log("has data");
        d3.select(d3Container.current).selectAll("*").remove();
        const format = d3.format(",d");
        const color = d3.scaleOrdinal().domain(data).range(["#fc9c02"]);
        const maliciousColor = d3
          .scaleOrdinal()
          .domain(data)
          .range(["#b50000"]);
        const purpleColor = d3.scaleOrdinal().domain(data).range(["#702963"]);
        const root = d3.pack().size([width, height]).padding(1.5)(
          d3.hierarchy({ children: data }).sum((d) => d.count),
        );

        const svgElement = d3
          .select(d3Container.current)
          .selectAll("circle")
          .data(root.leaves())
          .enter()
          .append("g")
          .attr("class", "circle")
          .attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
          });

        svgElement
          .append("circle")
          .attr("id", function (d) {
            return d.data.id;
          })
          .attr("r", 0) // Start with radius 0
          .style("fill", function (d) {
            var domainTags = getTags(dashboardDetailsObject, d.data.domain);
            let bubbleColor;
            if (domainTags.length > 0) {
              domainTags.forEach((tag) => {
                if (maliciousTags.has(tag)) {
                  bubbleColor = "red";
                } else if (adultSiteTags.has(tag)) {
                  bubbleColor = "purple";
                }
              });
            }
            return bubbleColor === "red"
              ? maliciousColor(d.data.id)
              : bubbleColor === "purple"
                ? purpleColor(d.data.id)
                : color(d.data.id);
          })
          .transition() // Apply transition to each circle
          .duration(1000) // Set duration of the transition in milliseconds
          .attr("r", function (d) {
            return d.r; // Change the radius to its final size
          });

        svgElement
          .append("clipPath")
          .attr("id", function (d) {
            return "clip-" + d.data.id;
          })
          .append("use")
          .attr("xlink:href", function (d) {
            return "#" + d.data.id;
          });
        ////// Take out the following section if you would like to remove the TOOLTIP from the thumnail card. //////
        svgElement
          .append("text")
          .attr("dy", ".3em")
          .style("text-anchor", "middle");

        svgElement.append("title").text(function (d) {
          return (
            "Domain: " + d.data.domain + "\n" + "Times blocked: " + d.data.count
          );
        });
        //////end of tool tip section //////
      }
    }
  }, []); //is this the corrct dependency array?

  function getTags(maliciousData, domain) {
    if (domain === undefined) {
      return [];
    }
    if (domain in maliciousData) {
      return maliciousData[domain.toString()].tags;
    }
    return [];
  }

  function setMaliciousData(maliciousDataList) {
    const returnData = {};
    if (maliciousDataList !== null) {
      maliciousDataList.forEach((element) => {
        returnData[element.domain] = element;
      });
    }
    return returnData;
  }

  return (
    <div>
      <svg ref={d3Container} width={width} height={height}></svg>
    </div>
  );
}

export default BlockedDomainsThumbnail;
