import React from "react";
import { PiSpinner } from "react-icons/pi";

import {
  Chart as ChartJS,
  defaults,
  ArcElement,
  // Tooltip,
  Legend,
} from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import Tooltip from "@mui/material/Tooltip";

ChartJS.register(ArcElement, Legend);
// ChartJS.register(ArcElement, Tooltip, Legend);
defaults.plugins.title.color = "black";

function MyDoughnut(props) {
  let total = props.totalWebDomains;
  let blocked = props.accessAttemptsBlocked;
  let malicious = props.maliciousDomainsBlocked;
  let percentage = Math.floor((blocked / total) * 100) || 0;

  const getColor = (percentage) => {
    const color = {
      green: "#1E7C1E",
      orange: "rgb(255, 156, 2)",
      red: "#B50000",
    };
    if (malicious) return color.red;
    if (percentage <= 10) return color.green;
    if (percentage >= 30) return color.red;
    return color.orange;
  };

  const data = {
    labels: [
      `Blocked Traffic: ${blocked}`,
      `Allowed Traffic: ${total - blocked}`,
    ],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [getColor(percentage), "rgb(211, 211, 211)"],
      },
    ],
  };

  const options = {
    aspectRatio: 10 / 8,
    plugins: {
      legend: {
        display: false,
      },
    },

    rotation: 270,
    circumference: 180,
    cutout: `75%`,
    animation: {
      duration: 2500,
      animateRotate: true,
    },
  };

  const innerLabel = {
    id: "innerLabel",
    afterDatasetDraw(chart, args, pluginOptions) {
      const { ctx } = chart;
      const meta = args.meta;
      let x = meta.data[0].x;
      let y = meta.data[0].y;
      const fontColor = getColor(percentage);
      ctx.save();
      ctx.textAlign = "center";
      let fontSize = ctx.canvas.clientWidth * 0.15; // Font size for numbers
      ctx.font = fontSize + "px Arial";
      ctx.fillStyle = fontColor;
      ctx.fillText(`${percentage}%`, x, y - ctx.canvas.clientHeight * 0.25);
      fontSize = ctx.canvas.clientWidth * 0.05; // Font size for other parts
      ctx.font = fontSize + "px Arial";
      ctx.fillStyle = props.mode ? "#BEC0C2" : "#58595b";
      ctx.fillText(
        "of your traffic comprised",
        x,
        y - ctx.canvas.clientHeight * 0.13,
      );
      ctx.fillText("of intrusions", x, y - ctx.canvas.clientHeight * 0.05);
      ctx.restore();
    },
  };

  const plugins = [innerLabel];

  return (
    <>
      {props.mainSpinner ? (
        <div className="pageload-spinner">
          <PiSpinner
            style={{
              fontSize: "5rem",
              animation: "spin 2s linear infinite",
              marginTop: "80px",
            }}
          />
        </div>
      ) : (
        <div className="chart-container donut-container">
          {malicious ? (
            <Tooltip
              title="Your threat level is high because a malicious domain has been detected. See Blocked Domain Insights for more information."
              placement="bottom-end"
              arrow
            >
              <div className="malicious-alert">
                <i class="fa-solid fa-triangle-exclamation"></i>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
          <div className="chart-title-container donut-title">
            <p className="chart-title">Privacy and Security Threat Summary</p>
            <p className="chart-subtitle">(last 24 hours)</p>
          </div>
          <div className="chart">
            <Doughnut data={data} options={options} plugins={plugins} />
          </div>

          <div className="chart-legend">
            <div className="legend">
              <div className="box-legend normal-risk"></div>
              <div className="text-lable">normal risk level</div>
            </div>

            <div className="legend">
              <div className="box-legend elevated-risk"></div>
              <div className="text-lable">elevated risk level</div>
            </div>

            <div className="legend">
              <div className="box-legend high-risk"></div>
              <div className="text-lable">high risk level</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyDoughnut;
