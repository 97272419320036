import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import { blockWebsites } from "../api";
import ProductType from "../constants/Constants";
import "../css/new-dashboard.css";

function BlockedDomains(props) {
  const { availWidth, availHeight } = window.screen;
  const width = availWidth < 750 ? availWidth * 0.75 : 500; //changes the width of a container with the bubbles
  const height = availWidth < 750 ? availHeight * 0.4 : 550;
  const data = props.domainsBlocked;
  const dashboardDetails = props.uniqueMalwareDomainsBlocked;
  const dashboardDetailsObject = setMaliciousData(
    props.uniqueMalwareDomainsBlocked,
  );
  let maliciousTags = new Set([
    "malware",
    "phishing",
    "botnet",
    "keylogger",
    "spam",
    "spyware",
    "proxy",
  ]);
  let adultSiteTags = new Set(["adult"]);
  const d3Container = useRef(null);

  useEffect(() => {
    if (data != null) {
      if (data.length > 0 && dashboardDetails !== null) {
        d3.select(d3Container.current).selectAll("*").remove();
        const format = d3.format(",d");
        const color = d3.scaleOrdinal().domain(data).range(["#fc9c02"]);
        const maliciousColor = d3
          .scaleOrdinal()
          .domain(data)
          .range(["#b50000"]);
        const purpleColor = d3.scaleOrdinal().domain(data).range(["#702963"]);
        const root = d3.pack().size([width, height]).padding(1.5)(
          d3.hierarchy({ children: data }).sum((d) => d.count),
        );

        // -1- Create a tooltip div that is hidden by default:
        const tooltip = d3.select("#selected_bubble_tooltip");

        // -2- Create 3 functions to show / update (when mouse move but stay on same circle) / hide the tooltip
        const showTooltip = function (event, d) {
          if (d3.select("#selected_bubble_tooltip").classed("clicked")) {
            return;
          }
          tooltip.transition().duration(200);
          tooltip
            .style("opacity", 1)
            .html(
              "<div class='bd-onhover'>" +
                "<b>Domain Name:</b> " +
                d.data.domain +
                "<br>" +
                "<b>No of times Blocked:</b> " +
                d.data.count +
                showCategory(dashboardDetailsObject, d.data.domain) +
                "</div>",
            )
            .style("left", event.x / 2 + "px")
            .style("top", event.y / 2 + 30 + "px");
        };
        const moveTooltip = function (event, d) {
          tooltip
            .style("left", event.x / 2 + "px")
            .style("top", event.y / 2 + 30 + "px");
        };
        const hideTooltip = function (event, d) {
          if (d3.select("#selected_bubble_tooltip").classed("clicked")) {
            return;
          }
          tooltip.transition().duration(200).style("opacity", 0);
        };
        const showStatus = function (event, d) {
          tooltip
            .style("opacity", 1)
            .html(
              "<div class='w-form-submitted-bd'> \"" +
                event +
                '" Successfully UnBlocked</div>',
            )
            .style("left", "30px")
            .style("top", "30px");
        };

        const Clicked = (event, d) => {
          d3.select("#selected_bubble_tooltip").classed("clicked", true);
          tooltip
            .style("opacity", 1)
            .html(
              "<b>Domain Name:</b> " +
                d.data.domain +
                "<br>" +
                "<b>No of times Blocked:</b> " +
                d.data.count +
                showCategory(dashboardDetailsObject, d.data.domain) +
                showUnBlock(dashboardDetailsObject, d.data.domain),
            )
            .style("left", event.x / 2 + "px")
            .style("top", event.y / 2 + 30 + "px");
          d3.select("#unblock").on("click", function () {
            blockWebsites(d.data.domain, false);
            d3.select("#selected_bubble_tooltip").classed("clicked", false);
            showStatus(d.data.domain);
          });
          d3.select("#cancel").on("click", function () {
            d3.select("#selected_bubble_tooltip").classed("clicked", false);
            hideTooltip();
          });
        };
        const svgElement = d3
          .select(d3Container.current)
          .selectAll("circle")
          .data(root.leaves())
          .enter()
          .append("g")
          .attr("class", "circle")
          .attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
          }) // -3- Trigger the functions
          .on("mouseover", showTooltip)
          .on("mousemove", moveTooltip)
          .on("mouseleave", hideTooltip)
          .on("click", Clicked);

        svgElement
          .append("circle")
          .attr("id", function (d) {
            return d.data.id;
          })
          .attr("r", function (d) {
            return d.r;
          })
          .style("fill", function (d) {
            var domainTags = getTags(dashboardDetailsObject, d.data.domain);
            if (domainTags.length > 0) {
              var bubbleColor;
              domainTags.forEach((tag) => {
                if (maliciousTags.has(tag)) {
                  bubbleColor = "red";
                } else if (adultSiteTags.has(tag)) {
                  bubbleColor = "purple";
                }
              });
            }

            return bubbleColor === "red"
              ? maliciousColor(d.data.id)
              : bubbleColor === "purple"
                ? purpleColor(d.data.id)
                : color(d.data.id);
          });

        svgElement
          .append("clipPath")
          .attr("id", function (d) {
            return "clip-" + d.data.id;
          })
          .append("use")
          .attr("xlink:href", function (d) {
            return "#" + d.data.id;
          });

        svgElement
          .append("text")
          .attr("dy", ".3em")
          .style("text-anchor", "middle");

        svgElement.append("title").text(function (d) {
          return (
            "Domain: " + d.data.domain + "\n" + "Times blocked: " + d.data.count
          );
        });
      }
    }
  });

  function getTags(maliciousData, domain) {
    if (domain === undefined) {
      return [];
    }
    if (domain in maliciousData) {
      return maliciousData[domain.toString()].tags;
    }
    return [];
  }
  function showCategory(maliciousData, domain) {
    var data = getTags(maliciousData, domain);
    if (data.length > 0) {
      return `<br><b>Category: <b> ${data}`;
    } else {
      return "<br></br>";
    }
  }
  function showUnBlock(dashboardDetailsObject, domain) {
    var domainTags = getTags(dashboardDetailsObject, domain);
    let returnMessage = "";
    let isDomainMalicious = false;

    if (domainTags.length > 0) {
      domainTags.forEach((tag) => {
        if (maliciousTags.has(tag)) {
          isDomainMalicious = true;
        }
      });
    }

    if (props.product !== ProductType.KAVALAN) {
      returnMessage = "Cannot unblock in Light/Trial Mode";
    } else {
      returnMessage =
        "<div class='button-container'><button id='unblock' class='button-9-bd w-button'/> <div class='button-9-bd-txt'>Unblock</div>  <button id='cancel' class='button-9-bd-cnl w-button' /> <div class='button-9-bd-cnl-txt'>Cancel</div> </div>";
    }

    if (isDomainMalicious) {
      returnMessage = "<p>Malicious domains cannot be unblocked.</p>";
    }

    return returnMessage;
  }

  function setMaliciousData(maliciousDataList) {
    const returnData = {};
    if (maliciousDataList !== null) {
      maliciousDataList.forEach((element) => {
        returnData[element.domain] = element;
      });
    }
    return returnData;
  }

  return (
    <div>
      <Box
        id="selected_bubble"
        /* style={{ border: "2px solid black" }} */
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          justifySelf: "center",
          backgroundColor: "#ddd",
          borderRadius: "5px",
          padding: "1em",
        }}
      >
        <div className="chart-title">
          Click on a bubble to see what was blocked.
        </div>
        <div
          id="selected_bubble_tooltip"
          className="tooltip"
          style={{
            display: "flex",
            flexDirection: "column",
            justifySelf: "center",
            alignItems: "center",
            opacity: "0",
            backgroundColor: "#ddd",
            borderRadius: "5px",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "5px",
            color: "black",
            fontFamily: "Proximanova, sans-serif",
            height: "100%",
            // width: "90%" /* is this the right thing? */,
          }}
        ></div>
      </Box>
      <svg
        ref={d3Container}
        width={width}
        height={height}
        style={{ paddingBottom: "1em" }}
      ></svg>
      <div className="chart-legend">
        <div className="legend">
          <div className="box-legend unique-blocked"></div>
          <div className="text-lable">Unique Sites Blocked</div>
        </div>
        <div className="legend">
          <div className="box-legend malicious-blocked"></div>
          <div className="text-lable">Malicious Sites Blocked</div>
        </div>
        <div className="legend">
          <div className="box-legend adult-blocked"></div>
          <div className="text-lable">Adult Content Blocked</div>
        </div>
      </div>
    </div>
  );
}

export default BlockedDomains;
